<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Min Stock Report
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Min Stock Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            class="mr-2 mg-t-5"
            v-model="min_stock_params.pageIndex"
            @change="selectTotalData"
          >
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="">All</option>
          </select>
          <select
            class="mr-2 mg-t-5"
            v-model="min_stock_params.fiscal_year"
            @change="getFilteredDataWithoutDates"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select
            v-model="min_stock_params.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <input
            type="text"
            v-model="min_stock_params.searched"
            class="filter-input mr-3 mg-t-5 float-right"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Item Code</th>
              <th class="wd-20p">Item Name</th>
              <th class="wd-15p">Category</th>
              <th class="wd-15p">Branch</th>
              <th class="wd-15p">Quantity</th>
            </tr>
          </thead>

          <tbody v-if="!loading && minStockWiseDatas.length > 0">
            <tr v-for="(item, index) in minStockWiseDatas" :key="index">
              <td>
                {{ pageSerialNo + index }}
              </td>
              <td>{{ item.item_code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.item_group }}</td>
              <td>{{ item.branch_name }}</td>
              <td>{{ item.total_available }} {{ item.title }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="10" class="tx-center">{{ errorMessage }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && minStockWiseDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import Services from "./Services/Services";
import _ from "lodash";

export default {
  components: {
    Paginate,
  },
  data() {
    return {
      sessionYears: ["2021-22"],
      branches: [],
      min_stock_params: {
        pageIndex: 50,
        branch: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      loading: false,
      error: false,
      errorMessage: "",
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("minStockReports", ["minStockWiseDatas"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.min_stock_params.pageIndex + 1;
      }
      this.offset = (page - 1) * this.min_stock_params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },

    searchKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.min_stock_params.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 1000),
    print(){
      window.print();
    },
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.min_stock_params.pageIndex,
        branch: this.min_stock_params.branch,
        searched: this.min_stock_params.searched,
        page_reload: this.pageReload,
        offset: this.min_stock_params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.min_stock_params.pageIndex,
        branch: this.min_stock_params.branch,
        searched: this.min_stock_params.searched,
        page_reload: this.pageReload,
        offset: this.min_stock_params.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getMinStockWiseFilterDatas(params)
        .then((res) => {
          this.$store.commit(
            "minStockReports/setMinStockWiseDatas",
            res.data.data.data
          );
          // this.purchaseOrders = res.data.data.purchaseOrder;
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download() {
      //  console.log(this.params);
      Services.getMinStockWiseExcel(this.min_stock_params)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const elink = document.createElement("a");
          elink.download = "min-stock-report.xlsx";
          elink.style.display = "none";
          elink.style.font = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>